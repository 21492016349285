.consultingRecord {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #fff;
  padding: 0.14rem 0.16rem;
}
.consultingRecord .consultingRecordItem {
  padding: 0.15rem 0.12rem;
  border-radius: 0.1rem;
  box-shadow: 0 0.05rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 0.12rem;
}
.consultingRecord .consultingRecordItem .timeAndType {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.27rem;
}
.consultingRecord .consultingRecordItem .time {
  font-size: 0.12rem;
  font-weight: 400;
  color: #444444;
  line-height: 0.12rem;
}
.consultingRecord .consultingRecordItem .itemLine {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.12rem;
  font-size: 0.14rem;
  font-weight: 400;
}
.consultingRecord .consultingRecordItem .itemLine .itemLineBefore {
  display: inline-block;
  text-align: left;
  color: #999999;
  line-height: 0.2rem;
  margin-right: 0.26rem;
  flex-shrink: 0;
}
.consultingRecord .consultingRecordItem .itemLine .itemLineAfter {
  width: 100%;
  color: #444444;
  line-height: 0.2rem;
}
.consultingRecord .consultingRecordItem .itemLine .flagcolor {
  float: right;
  color: #397CFF;
}
.consultingRecord .consultingRecordItem .itemLine .showMore {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.consultingRecord .consultingRecordItem .itemLine:last-child {
  margin-bottom: 0;
}
.consultingRecord .consultingRecordItem .typeCode {
  display: inline-block;
  text-align: center;
  margin-left: 0.06rem;
  font-size: 0.12rem;
  border-radius: 50%;
  padding: 0.03rem;
  color: #fff;
  transform: scale(0.9);
}
.consultingRecord .consultingRecordItem .type {
  font-size: 0.12rem;
}
.consultingRecord .consultingRecordItem .complexBg {
  background: #00B769;
}
.consultingRecord .consultingRecordItem .firstBg {
  background: #397CFF;
}
.consultingRecord .consultingRecordItem .alreadyComplete {
  color: #397CFF;
}
.consultingRecord .consultingRecordItem .notComplete {
  color: #FF5344;
}
.consultingRecord .noData {
  text-align: center;
}
.consultingRecord .noData img {
  width: 1.5rem;
  height: 1.14rem;
  margin: 0.3rem 0 0.4rem;
}
.consultingRecord .ant-spin-nested-loading div.ant-spin {
  width: 100%;
}
.consultingRecord .ant-spin-nested-loading span.ant-spin-dot-spin {
  margin-top: 1.5rem;
}
