.seeDoctor-content {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 1.9rem);
  overflow: auto;
  background: #fff;
  padding: 0.14rem 0.16rem;
}
.seeDoctor-content .loading-tip {
  width: 100%;
}
.seeDoctor-content .loading-tip .ant-spin-dot {
  margin-top: 1.5rem !important;
}
.seeDoctor-content .no-data-content {
  width: 100%;
  text-align: center;
}
.seeDoctor-content .no-data-content img {
  width: 2rem;
}
.seeDoctor-content .seeDoctor-record-item {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 0.12rem;
}
.seeDoctor-content .seeDoctor-record-item .time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  padding: 0.15rem 0.12rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.seeDoctor-content .seeDoctor-record-item .time-row .left-content .time {
  display: inline-block;
  font-size: 0.12rem;
  color: #444444;
  margin-right: 0.05rem;
}
.seeDoctor-content .seeDoctor-record-item .time-row .left-content .record-type {
  display: inline-block;
  line-height: 1;
  padding: 0.02rem;
  font-size: 0.09rem;
  border-radius: 50%;
  background: #444444;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}
.seeDoctor-content .seeDoctor-record-item .time-row .right-content {
  font-size: 0.12rem;
}
.seeDoctor-content .seeDoctor-record-item .record-message {
  padding: 0.12rem;
  line-height: 1.2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.seeDoctor-content .seeDoctor-record-item .record-message .outpatient-name {
  font-size: 0.14rem;
  color: #444444;
  font-weight: 500;
}
.seeDoctor-content .seeDoctor-record-item .record-message .message-content {
  display: flex;
  flex-wrap: wrap;
}
.seeDoctor-content .seeDoctor-record-item .record-message .message-content .message-item {
  flex: 50%;
  margin-top: 0.12rem;
  display: flex;
}
.seeDoctor-content .seeDoctor-record-item .record-message .message-content .message-item .item-title {
  color: #999;
  font-size: 0.14rem;
  width: 0.7rem;
  margin-right: 0.12rem;
}
.seeDoctor-content .seeDoctor-record-item .record-message .message-content .message-item .item-value {
  color: #444444;
  font-size: 0.14rem;
  flex: 1;
}
.seeDoctor-content .seeDoctor-record-item .spot-diagnosis {
  padding: 0.12rem;
  font-size: 0.12rem;
  color: #666666;
}
