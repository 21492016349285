.infoBinding {
  padding: 0.4rem 0.3rem;
  font-weight: 400;
  font-size: 0.14rem;
  font-family: PingFangSC, PingFangSC-Regular;
}
.infoBinding .orgName {
  font-size: 0.22rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.04rem;
}
.infoBinding .info {
  color: #999999;
}
.infoBinding .searchBtn {
  margin: 0.24rem 0;
  background: #397cff;
  color: #feffff;
  border-radius: 0.21rem;
  height: 0.4rem;
  width: 100%;
  font-size: 0.15rem;
}
.infoBinding .extra {
  font-size: 0.14rem;
  color: #cccccc;
  cursor: pointer;
  margin-top: -0.04rem;
  margin-right: -0.16rem;
}
.infoBinding .extra.canGetCode {
  color: #333333;
}
.infoBinding .getCodeInout {
  margin-top: 0.2rem;
}
.infoBinding .bingResultList-item {
  display: flex;
  margin-bottom: 0.12rem;
  padding-bottom: 0.12rem;
  border-bottom: 0.01rem solid #eeeeee;
  justify-content: space-between;
}
.infoBinding .bingResultList .left {
  display: flex;
}
.infoBinding .bingResultList .left p {
  margin-bottom: 0;
}
.infoBinding .bingResultList .left p.userName {
  font-size: 0.15rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #333333;
}
.infoBinding .bingResultList .left p.recordNum {
  margin-top: 0.04rem;
  font-size: 0.12rem;
  color: #999999;
}
.infoBinding .bingResultList .bindImg {
  width: 0.26rem;
  height: 0.26rem;
  margin-top: 0.09rem;
}
.infoBinding .bingResultList .userImg {
  width: 0.44rem;
  height: 0.44rem;
  margin-right: 0.08rem;
  border-radius: 0.02rem;
  position: relative;
}
.infoBinding .bingResultList .userImg img {
  width: 100%;
  height: 100%;
}
.infoBinding .bingResultList .userImg .sexImg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.14rem;
  height: 0.14rem;
}
.infoBinding .noListData {
  width: 100%;
  text-align: center;
  font-size: 0.13rem;
  color: #666;
}
.infoBinding .bindBtn {
  display: inline-block;
  text-align: center;
  line-height: 0.27rem;
  width: 0.62rem;
  height: 0.26rem;
  margin-top: 0.1rem;
  color: #3d8af2;
  border: 0.01rem solid #eeeeee;
  border-radius: 0.14rem;
}
.infoBinding .am-list-item {
  background: none !important;
  padding-left: 0 !important;
  border-bottom: 0.01rem solid #eeeeee;
}
.infoBinding .am-list-line::after {
  content: none !important;
}
.infoBinding .ant-btn-primary[disabled] {
  color: #feffff;
  background: rgba(57, 124, 255, 0.3);
}
.infoBinding .am-list-item .am-input-control input {
  color: #333333;
}
.infoBinding ::-webkit-input-placeholder {
  font-size: 0.15rem;
  color: #cccccc;
}
.infoBinding ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 0.15rem;
  color: #cccccc;
}
.infoBinding :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 0.15rem;
  color: #cccccc;
}
.infoBinding :-ms-input-placeholder {
  font-size: 0.15rem;
  color: #cccccc;
}
