.addReturnVisit {
  padding: 0.14rem 0;
  font-size: 0.14rem;
  height: 100%;
  font-family: PingFangSC, PingFangSC-Regular;
  background-color: #fff;
}
.addReturnVisit .addList {
  height: calc(100% - 0.78rem);
  overflow-y: auto;
  padding: 0 0.16rem;
}
.addReturnVisit .title {
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  font-size: 0.16rem;
  color: #222222;
  padding: 0 0.16rem;
}
.addReturnVisit .visitKey {
  color: #666666;
}
.addReturnVisit .visitKey.must::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.addReturnVisit .bottom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.2rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-top: 0.01rem solid #efefef !important;
  height: 0.6rem;
  box-sizing: border-box;
}
.addReturnVisit .bottom-button .info-button {
  height: 0.36rem;
  font-size: 0.15rem;
  width: 1.6rem;
  margin: 0 0.08rem;
  background: #feffff;
  border-radius: 0.2rem;
  box-sizing: border-box;
}
.addReturnVisit .bottom-button .back {
  color: #666666;
}
.addReturnVisit .bottom-button .save {
  background: #397CFF;
  color: #fff;
}
.addReturnVisit .am-list-content {
  font-size: 0.14rem !important;
  font-weight: 400 !important;
  display: flex !important;
  justify-content: space-between !important;
}
.addReturnVisit .am-textarea-label.am-textarea-label-5 {
  font-size: 0.14rem !important;
}
.addReturnVisit .am-textarea-control textarea {
  text-align: right;
  font-size: 0.14rem;
  color: #444444 !important;
}
.addReturnVisit .am-list-item .am-list-line .am-list-extra {
  font-size: 0.14rem;
}
.addReturnVisit .am-list-item {
  padding-left: 0;
}
.addReturnVisit .am-list-body::before {
  height: 0 !important;
}
.addReturnVisit .am-list-item.am-textarea-item {
  padding-left: 0;
}
.addReturnVisit ::-webkit-input-placeholder {
  color: #888888 !important;
}
.addReturnVisit ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #888888 !important;
}
.addReturnVisit :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #888888 !important;
}
.addReturnVisit :-ms-input-placeholder {
  color: #888888 !important;
}
.addReturnVisit .ant-btn-primary {
  background: #397cff !important;
}
.addReturnVisit .ant-btn {
  border-color: #eeeeee;
}
.addReturnVisit .am-textarea-label.am-textarea-label-5 {
  width: auto !important;
}
