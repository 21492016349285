.bindResult {
  padding-top: 1.2rem;
  text-align: center;
}
.bindResult .resultStatusImg {
  width: 0.56rem;
  height: 0.56rem;
  margin-bottom: 0.16rem;
}
.bindResult .resultStatusInfo {
  font-size: 0.16rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #333333;
}
