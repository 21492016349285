.am-drawer-open {
  position: fixed;
  z-index: 10;
}
.am-drawer-open .am-drawer-sidebar > div {
  border-radius: 0.2rem 0.2rem 0 0;
  padding-top: 0.2rem;
  color: #515A6E;
  background: #FFFFFF;
}
.am-drawer-open .am-drawer-sidebar > div .title {
  color: #0B1B31;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  margin-bottom: 0.15rem;
  font-weight: bold;
}
