.visitList {
  background-color: #ffffff;
  height: 100%;
  padding: 0.14rem 0;
  color: #444444;
  font-size: 0.14rem;
  overflow: hidden;
  font-family: PingFangSC, PingFangSC-Regular;
}
.visitList .noData {
  text-align: center;
}
.visitList .noData img {
  width: 1.5rem;
  height: 1.14rem;
  margin-top: 0.4rem;
}
.visitList .operations {
  padding: 0 0.14rem;
  margin-bottom: 0.16rem;
}
.visitList .operations .btn {
  height: 0.28rem;
  line-height: 0.2rem;
  padding: 0.04rem 0.08rem;
  color: #397CFF;
  border-color: #397CFF;
}
.visitList .card {
  box-shadow: 0rem 0rem 0.2rem 0rem rgba(0, 0, 0, 0.05);
  border-radius: 0.1rem;
  margin-bottom: 0.12rem;
  background-color: #fff;
}
.visitList .card-header {
  min-height: 0.41rem;
  border-bottom: 0.01rem solid #efefef;
  padding: 0 0.12rem;
  color: #333333;
  line-height: 0.41rem;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
}
.visitList .card-header .cancel {
  color: #999999;
}
.visitList .card-header .notFollow {
  color: #FF5344;
}
.visitList .card-header .follow {
  color: #397CFF;
}
.visitList .card-body {
  min-height: 2.69rem;
  display: flex;
  flex-direction: column;
  padding: 0.1rem 0.12rem;
}
.visitList .card-body .content-item {
  display: flex;
  margin-bottom: 0.12rem;
}
.visitList .card-body .content-item .visitKey {
  display: inline-block;
  width: 0.88rem;
  color: #999999;
  margin-right: 0.12rem;
}
.visitList .card-body .content-item .visitContent {
  width: calc(100vw - 1.6rem);
  word-wrap: break-word;
}
.visitList .card-body .content-item .openWord {
  float: right;
  color: #397CFF;
}
.visitList .card-bottom {
  min-height: 0.51rem;
  padding: 0.13rem 0.15rem;
  border-top: 0.01rem solid #efefef;
}
.visitList .card-bottom .submit-btn {
  background-color: #397cff;
}
.visitList .card-bottom .btn {
  float: right;
  padding: 0.07rem 0.22rem;
  width: 0.78rem;
  height: 0.26rem;
  line-height: 0.12rem;
  font-size: 0.12rem;
  margin-left: 0.12rem;
  border: 0.01rem solid #b6b6b6;
  border-radius: 0.14rem;
  color: #666666;
}
.visitList .card-bottom .submit-btn {
  color: #FFFFFF;
  border: none;
}
.visitList .am-list-body::before,
.visitList .am-list-body::after {
  content: none !important;
}
.visitList .am-list-body {
  border: 0 !important;
}
.visitList .visitList {
  padding: 0.02rem 0.16rem;
}
.visitList .am-list-view-scrollview {
  overflow: hidden !important;
  overflow-y: auto !important;
}
