.order-detail-wrap {
  overflow-y: auto;
  height: 100%;
  background: #fff;
  position: relative;
  padding-bottom: 0.5rem;
}
.order-detail-wrap p {
  padding: 0;
  margin: 0;
}
.order-detail-wrap .divider {
  height: 0.08rem;
  background: #EFF3F5;
}
.order-detail-wrap .order-detail {
  padding: 0 0.16rem;
  background: #fff;
}
.order-detail-wrap .order-detail .item {
  border-bottom: 0.01rem solid #F0F0F1;
}
.order-detail-wrap .order-detail .item h3 {
  color: #222;
  font-size: 0.16rem;
  line-height: 0.22rem;
  font-weight: bold;
  padding: 0.16rem 0;
  margin: 0;
}
.order-detail-wrap .order-detail .item .date {
  line-height: 0.4rem;
  color: #444;
  font-size: 0.12rem;
  border-bottom: 0.01rem solid #EFEFEF;
  padding: 0 0.12rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.12rem;
}
.order-detail-wrap .order-detail .item .date span {
  font-weight: bold;
}
.order-detail-wrap .order-detail .item .label {
  padding-bottom: 0.12rem;
  color: #444;
  font-size: 0.14rem;
  line-height: 0.2rem;
  display: flex;
}
.order-detail-wrap .order-detail .item .label span {
  width: 0.8rem;
  color: #999;
}
.order-detail-wrap .order-detail .item .tips {
  font-size: 0.12rem;
  color: #999999;
  line-height: 0.18rem;
  padding: 0.04rem 0 0.08rem;
}
.order-detail-wrap .order-detail .item .detail-item {
  border: 0.01rem solid #EEEEEE;
  border-radius: 0.03rem;
  padding: 0.12rem;
  font-size: 0.12rem;
  color: #666666;
  position: relative;
  margin-bottom: 0.16rem;
}
.order-detail-wrap .order-detail .item .detail-item p {
  display: flex;
  padding-bottom: 0.08rem;
}
.order-detail-wrap .order-detail .item .detail-item p span {
  width: 50%;
}
.order-detail-wrap .order-detail .item .detail-item .pattern-name {
  font-size: 0.14rem;
  color: #444444;
  line-height: 0.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.order-detail-wrap .order-detail .item .detail-item .pattern-name span {
  margin-left: 0.04rem;
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 0.16rem;
  color: #fff;
  font-weight: normal;
  font-size: 0.1rem;
  background: #05C494;
  text-align: center;
  line-height: 0.16rem;
}
.order-detail-wrap .order-detail .item .detail-item .discount {
  background: #C2D7FF;
  color: #397CFF;
  line-height: 0.18rem;
  font-size: 0.12rem;
  padding: 0.02rem 0.08rem;
  border-radius: 0 0.03rem 0 0.08rem;
  position: absolute;
  right: 0;
  top: 0;
}
.order-detail-wrap .empty-img {
  width: 1.5rem;
  margin: 0.3rem auto 0;
  display: block;
}
.order-detail-wrap .btn {
  width: 100%;
  background: #fff;
  padding: 0.07rem 0.2rem;
  border-top: solid 0.01rem #EFEFEF;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.order-detail-wrap .btn button {
  width: 100%;
  height: 0.36rem;
  border: 0.01rem solid #EEEEEE;
  font-size: 0.15rem;
  color: #666;
  background: #fff;
  border-radius: 0.36rem;
  font-weight: bold;
  cursor: pointer;
}
