.friends-relation-edit-modal .am-modal-content {
  border-radius: 0.12rem 0.12rem 0 0;
}
.friends-relation-edit-modal .am-modal-content .am-modal-close {
  margin-top: 0.14rem;
  cursor: pointer;
}
.friends-relation-edit-modal .am-modal-content .am-modal-close .am-modal-close-x {
  width: 0.12rem;
  height: 0.12rem;
}
.friends-relation-edit-modal .am-modal-content .am-modal-header {
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #222222;
  font-size: 0.16rem;
  line-height: 0.22rem;
  padding: 0;
}
.friends-relation-edit-modal .bottom-container {
  background: #FFFFFF;
  border-radius: 0.04rem;
  box-shadow: 0 0.02rem 0.1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0 0.16rem;
  max-height: 60vh;
  overflow-y: auto;
}
.friends-relation-edit-modal .bottom-container .am-list-item {
  padding-left: 0;
}
.friends-relation-edit-modal .bottom-container .am-list-item .am-list-line {
  height: 0.52rem;
  border-bottom: 0.01rem solid #EEEEEE;
}
.friends-relation-edit-modal .bottom-container .am-list-item .am-list-line .info-item-select {
  color: #666666;
  font-size: 0.14rem;
  line-height: 0.2rem;
}
.friends-relation-edit-modal .bottom-container .am-list-item .am-list-line .am-list-extra {
  font-size: 0.14rem;
  line-height: 0.2rem;
}
.friends-relation-edit-modal .bottom-container .am-list-item .am-list-line .am-list-arrow {
  width: 0.12rem;
  height: 0.12rem;
}
.friends-relation-edit-modal .bottom-container .am-list-item .am-textarea-control {
  padding-top: 0;
}
.friends-relation-edit-modal .bottom-container .info-item {
  height: 0.52rem;
  font-size: 0.14rem;
  line-height: 0.2rem;
  padding: 0.07rem 0.2rem 0.07rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.01rem solid #EEEEEE;
}
.friends-relation-edit-modal .bottom-container .info-item:last-of-type {
  margin-bottom: 0;
}
.friends-relation-edit-modal .bottom-container .info-item .info-label {
  color: #666666;
  width: 0.82rem;
  flex-shrink: 0;
  text-align: left;
}
.friends-relation-edit-modal .bottom-container .info-item .info-value {
  color: #444444;
  word-break: break-all;
}
.friends-relation-edit-modal .bottom-container .info-item .red-font {
  color: #FF5344;
}
.friends-relation-edit-modal .bottom-container .no-bottom-border {
  border-bottom: 0;
}
.friends-relation-edit-modal .bottom-container textarea {
  font-size: 0.14rem;
  line-height: 0.2rem;
}
.friends-relation-edit-modal .bottom-operation-box {
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.16rem;
}
.friends-relation-edit-modal .bottom-operation-box .save-btn {
  flex: 1;
  height: 0.4rem;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.15rem;
  line-height: 0.22rem;
  border: 0.01rem solid #B6B6B6;
  color: #FFFFFF;
  background: #397CFF;
}
.name-search-modal .am-list-line {
  border-bottom: none!important;
}
.name-search-modal .am-list-line:after {
  content: none!important;
}
.name-search-modal .am-list-line .am-input-label {
  width: 0;
}
.name-search-modal .am-list-line .am-input-label .before-input-icon {
  position: absolute;
  width: 0.14rem;
  height: 0.14rem;
  left: 0.14rem;
  top: 0.16rem;
}
.name-search-modal .am-list-line .am-input-control input {
  font-size: 0.14rem;
  border: 1px solid #EEEEEE;
  padding: 0.12rem 0.28rem 0.12rem;
  border-radius: 0.04rem;
}
.name-search-modal .get-more-btn {
  color: #397CFF;
  font-size: 0.14rem;
  line-height: 0.2rem;
  cursor: pointer;
  height: 0.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.name-search-modal .friends-list-container {
  max-height: 3rem;
  min-height: 1.8rem;
  padding: 0 0.16rem;
  overflow-y: auto;
}
.name-search-modal .friends-list-container .friends-box {
  text-align: left;
  padding: 0.12rem 0;
  border-bottom: 0.01rem solid #EEEEEE;
}
.name-search-modal .friends-list-container .friends-box .name-phone {
  color: #333333;
  font-size: 0.15rem;
  line-height: 0.22rem;
  margin-bottom: 0.04rem;
}
.name-search-modal .friends-list-container .friends-box .number {
  color: #999999;
  font-size: 0.12rem;
  line-height: 0.18rem;
}
.name-search-modal .friends-list-container .no-data-content {
  width: 100%;
  text-align: center;
}
.name-search-modal .friends-list-container .no-data-content img {
  width: 2rem;
}
