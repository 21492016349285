.basic-information-box .basic-info {
  padding: 0.16rem 0 0;
  border-bottom: 0.01rem solid #F0F0F1;
}
.basic-information-box .basic-info:first-of-type {
  padding-top: 0;
}
.basic-information-box .basic-info:last-of-type {
  border-bottom: none;
}
.basic-information-box .basic-info .info-title {
  color: #222222;
  font-size: 0.16rem;
  line-height: 0.22rem;
  margin-bottom: 0.16rem;
  font-weight: bold;
}
.basic-information-box .basic-info .info-item {
  font-size: 0.14rem;
  line-height: 0.2rem;
  margin-bottom: 0.15rem;
  display: flex;
}
.basic-information-box .basic-info .info-item .info-label {
  color: #999999;
  width: 0.68rem;
  flex-shrink: 0;
}
.basic-information-box .basic-info .info-item .info-value {
  color: #444444;
  word-break: break-all;
}
.basic-information-box .basic-info .info-item .info-value .copy-icon {
  margin-left: 0.1rem;
}
.basic-information-box .copy-box {
  position: absolute;
  left: -1000px;
}
