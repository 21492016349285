.patient-details-box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
  background: #FFFFFF;
  padding: 0 0.16rem;
}
.patient-details-box > div {
  flex: 1;
  overflow-y: auto;
}
.patient-details-box .patient-details-spin {
  width: 100%;
}
.patient-details-box .patient-details-spin .ant-spin-dot {
  margin-top: 1.5rem;
}
.patient-details-box ::-webkit-scrollbar {
  display: none;
  /* background-color:transparent; */
}
.patient-details-box .header-box {
  overflow-x: auto;
  padding: 0.06rem 0;
  display: flex;
  flex: unset;
  flex-shrink: 0;
  height: 0.54rem;
  align-items: center;
}
.patient-details-box .header-box .arrow-icon {
  width: 0.2rem;
  position: absolute;
  top: 0.17rem;
  display: none;
  cursor: pointer;
}
.patient-details-box .header-box .left-arrow {
  left: 0.2rem;
}
.patient-details-box .header-box .right-arrow {
  right: 0.2rem;
}
.patient-details-box .header-box:hover .arrow-icon {
  display: block;
}
.patient-details-box .header-box .header-item {
  background: #F1F2F6;
  font-size: 0.14rem;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.04rem 0.08rem;
  margin-right: 0.08rem;
  border-radius: 0.02rem;
  cursor: pointer;
  white-space: nowrap;
}
.patient-details-box .header-box .header-item:last-of-type {
  margin-right: 0;
}
.patient-details-box .header-box .current-item {
  background: #397CFF;
  color: #FFFFFF;
}
.patient-details-box .noData {
  text-align: center;
}
.patient-details-box .noData img {
  width: 1.3rem;
  margin: 0.2rem 0 0.2rem;
}
