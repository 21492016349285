.order-list-wrap {
  height: 100%;
  background: #fff;
}
.order-list-wrap p {
  padding: 0;
  margin: 0;
}
.order-list-wrap .divider {
  height: 0.08rem;
  background: #EFF3F5;
}
.order-list-wrap .empty-img {
  width: 1.5rem;
  margin: 0.3rem auto 0;
  display: block;
}
.order-list-wrap .order-status {
  background: #fff;
  padding: 0.16rem 0.16rem 0.2rem;
}
.order-list-wrap .order-status button {
  padding: 0 0.08rem;
  height: 0.28rem;
  border: 0;
  outline: none;
  margin-right: 0.08rem;
  font-size: 0.14rem;
  color: #444;
  border-radius: 0.02rem;
  background: #F1F2F6;
}
.order-list-wrap .order-status .act {
  color: #fff;
  background: #397CFF;
}
.order-list-wrap .am-list-body::before,
.order-list-wrap .am-list-body::after {
  content: none !important;
}
.order-list-wrap .am-list-body {
  border: 0 !important;
}
.order-list-wrap .order-list {
  padding: 0 0.16rem;
  background: #fff;
}
.order-list-wrap .order-list .item {
  box-shadow: 0 0 0.15rem 0 #eee;
  margin-bottom: 0.08rem;
}
.order-list-wrap .order-list .item .date {
  line-height: 0.4rem;
  color: #444;
  font-size: 0.12rem;
  border-bottom: 0.01rem solid #EFEFEF;
  padding: 0 0.12rem;
  height: 0.4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.12rem;
}
.order-list-wrap .order-list .item .date span {
  font-weight: bold;
}
.order-list-wrap .order-list .item .date .status_PAID {
  color: #00B769;
}
.order-list-wrap .order-list .item .label {
  padding-bottom: 0.12rem;
  color: #444;
  font-size: 0.14rem;
  line-height: 0.2rem;
  display: flex;
  padding-left: 0.12rem;
}
.order-list-wrap .order-list .item .label span {
  width: 1rem;
  color: #999;
}
.order-list-wrap .order-list .item .btn {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding-right: 0.12rem;
  border-top: solid 0.01rem #EFEFEF;
  height: 0.5rem;
}
.order-list-wrap .order-list .item .btn button {
  width: 0.68rem;
  height: 0.26rem;
  border: 0.01rem solid #B6B6B6;
  font-size: 0.12rem;
  color: #666;
  background: #fff;
  border-radius: 0.26rem;
}
.order-list-wrap .order-list .item .btn button a {
  color: #666;
}
.order-list-wrap .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  color: #999;
}
