.introducer-list-box .top-title {
  display: flex;
  justify-content: space-between;
}
.introducer-list-box .top-title .info-title {
  color: #222222;
  font-size: 0.16rem;
  line-height: 0.22rem;
  margin-bottom: 0.16rem;
  font-weight: bold;
}
.introducer-list-box .top-title .right-info {
  color: #666666;
  font-size: 0.14rem;
  line-height: 0.2rem;
}
.introducer-list-box .basic-info {
  padding: 0.16rem 0 0;
  border-bottom: 0.01rem solid #F0F0F1;
}
.introducer-list-box .basic-info:first-of-type {
  padding-top: 0;
}
.introducer-list-box .basic-info:last-of-type {
  border-bottom: none;
}
.introducer-list-box .basic-info .bottom-container {
  background: #FFFFFF;
  border-radius: 0.04rem;
  box-shadow: 0 0.02rem 0.1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0.16rem 0.12rem;
}
.introducer-list-box .basic-info .bottom-container .info-item {
  font-size: 0.14rem;
  line-height: 0.2rem;
  margin-bottom: 0.15rem;
  display: flex;
}
.introducer-list-box .basic-info .bottom-container .info-item:last-of-type {
  margin-bottom: 0;
}
.introducer-list-box .basic-info .bottom-container .info-item .info-label {
  color: #999999;
  width: 0.82rem;
  flex-shrink: 0;
}
.introducer-list-box .basic-info .bottom-container .info-item .info-value {
  color: #444444;
  word-break: break-all;
}
.introducer-list-box .basic-info .bottom-container .info-item .red-font {
  color: #FF5344;
}
.friends-relation-box .add-relation-btn {
  text-align: center;
  padding: 0.1rem 0;
  color: #397CFF;
  font-size: 0.14rem;
  line-height: 0.2rem;
  cursor: pointer;
}
.friends-relation-box .add-relation-btn > img {
  margin-right: 0.04rem;
  margin-top: -0.01rem;
}
.friends-relation-box .basic-info {
  border-bottom: 0;
}
.friends-relation-box .basic-info .bottom-container {
  position: relative;
  margin-bottom: 0.14rem;
}
.friends-relation-box .basic-info .bottom-container .operation-box {
  color: #9E9E9E;
  font-size: 0.12rem;
  line-height: 0.18rem;
  cursor: pointer;
  position: absolute;
  right: 0.12rem;
  top: 0.17rem;
}
.friends-relation-box .basic-info .bottom-container .operation-box > img {
  margin-left: 0.1rem;
  cursor: pointer;
}
