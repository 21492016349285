.addTemporaryReturnVisit {
  padding: 0.14rem 0;
  font-size: 0.14rem;
  font-weight: 400;
  font-family: PingFangSC, PingFangSC-Regular;
  background-color: #fff;
  height: 100%;
}
.addTemporaryReturnVisit .addList {
  height: calc(100vh - 3.1rem) !important;
  overflow-y: auto;
  padding: 0 0.16rem;
}
.addTemporaryReturnVisit .title {
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  font-size: 0.16rem;
  padding: 0 0.16rem;
  display: flex;
  color: #222222;
  justify-content: space-between;
}
.addTemporaryReturnVisit .title .cancel {
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  font-size: 0.14rem;
  color: #397CFF;
  cursor: pointer;
}
.addTemporaryReturnVisit .visitKey {
  color: #666666;
}
.addTemporaryReturnVisit .visitKey.must::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.addTemporaryReturnVisit .visitValue {
  color: #444444;
}
.addTemporaryReturnVisit .bottom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.16rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-top: 0.01rem solid #efefef !important;
  height: 0.6rem;
  box-sizing: border-box;
}
.addTemporaryReturnVisit .bottom-button .info-button {
  width: 50%;
  height: 0.36rem;
}
.addTemporaryReturnVisit .bottom-button .info-button:nth-child(2) {
  margin: 0 0.12rem;
}
.addTemporaryReturnVisit .bottom-button .back {
  color: #666666;
  background: #eeeeee;
}
.addTemporaryReturnVisit .bottom-button .save {
  color: #397cff !important;
  border: 1px solid #397cff !important;
}
.addTemporaryReturnVisit .am-list-item {
  padding-left: 0;
}
.addTemporaryReturnVisit .am-list-body::before {
  height: 0 !important;
}
.addTemporaryReturnVisit .am-list-item.am-textarea-item {
  padding-left: 0;
}
.addTemporaryReturnVisit .am-list-content {
  font-size: 0.14rem !important;
  font-weight: 400 !important;
  display: flex !important;
  justify-content: space-between !important;
}
.addTemporaryReturnVisit .am-textarea-label.am-textarea-label-5 {
  font-size: 0.14rem !important;
}
.addTemporaryReturnVisit .am-textarea-control textarea {
  text-align: right;
  font-size: 0.14rem;
  color: #444444 !important;
}
.addTemporaryReturnVisit .am-list-item .am-list-line .am-list-extra {
  font-size: 0.14rem;
}
.addTemporaryReturnVisit ::-webkit-input-placeholder {
  color: #888888 !important;
}
.addTemporaryReturnVisit ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #888888 !important;
}
.addTemporaryReturnVisit :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #888888 !important;
}
.addTemporaryReturnVisit :-ms-input-placeholder {
  color: #888888 !important;
}
.addTemporaryReturnVisit .ant-btn-primary {
  background: #397cff !important;
}
.addTemporaryReturnVisit .am-textarea-label.am-textarea-label-5 {
  width: auto !important;
}
