body {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei, STHeiti, SimSun, sans-serif !important;
}
.am-textarea-label,
.am-list-item {
  height: 0.52rem;
  line-height: 0.52rem !important;
}
.am-textarea-control {
  padding-top: 0.15rem !important;
}
.am-list-line {
  padding-right: 0!important;
}
.am-picker-col-indicator::after,
.am-textarea-item::after,
.am-list-line::after,
.am-list-body::after,
.am-picker-col-indicator::before,
.am-textarea-item::before,
.am-list-line::before,
.am-list-body::before {
  height: 0.01rem !important;
  background-color: #eee !important;
}
.am-textarea-control {
  padding-bottom: 0!important;
}
.am-list-arrow {
  width: 0.12rem !important;
  height: 0.12rem !important;
  background-image: url('./image/rightArrow.png');
}
.am-list-extra {
  flex-basis: 50% !important;
}
