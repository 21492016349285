.order-init-sum {
  background: #fff;
  padding: 0 0.16rem 0.16rem;
}
.order-init-sum .order-init-sum-box {
  border-radius: 0.04rem;
  border: 0.01rem solid #EEEEEE;
  padding: 0.12rem;
  font-size: 0.12rem;
  color: #999999;
  line-height: 0.18rem;
}
.order-init-sum .order-init-sum-box > div:nth-child(1) {
  border-bottom: 0.01rem #EEEEEE solid;
  margin-bottom: 0.12rem;
  padding-bottom: 0.12rem;
}
.order-init-sum .order-init-sum-box > div {
  display: flex;
}
.order-init-sum .order-init-sum-box > div div {
  width: 33.33%;
  padding-left: 0.13rem;
}
.order-init-sum .order-init-sum-box > div div > p:nth-child(2) {
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #444444;
  padding-top: 0.04rem;
  font-weight: bold;
}
.order-init-sum .order-init-sum-box .line {
  background: url("../../image/orderSumLine.png") no-repeat right center;
  background-size: 0.04rem 0.44rem;
}
