.patientInfo-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #EFF3F5;
}
.patientInfo-page .loading-tip {
  width: 100%;
}
.patientInfo-page .loading-tip .ant-spin-dot {
  margin-top: 1.5rem !important;
}
.patientInfo-page .patient-list {
  height: 100%;
  overflow: auto;
  padding: 0.1rem;
  background: #fff;
}
.patientInfo-page .patient-list .patient-item-content {
  display: flex;
  padding: 0.1rem;
  border-bottom: 0.01rem solid #EFEFEF;
}
.patientInfo-page .patient-list .patient-item-content .header-img {
  margin-right: 0.2rem;
}
.patientInfo-page .patient-list .patient-item-content .header-img img {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.1rem;
}
.patientInfo-page .patient-list .patient-item-content .message-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.patientInfo-page .patient-list .patient-item-content .message-info .name-text {
  font-size: 0.16rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
}
.patientInfo-page .patient-list .patient-item-content .message-info .phone-text {
  font-size: 0.14rem;
}
.patientInfo-page .base-info-content {
  padding: 0.16rem;
  display: flex;
  background: #fff;
}
.patientInfo-page .base-info-content .header-img {
  position: relative;
  width: 0.56rem;
  height: 0.56rem;
  margin-right: 0.16rem;
}
.patientInfo-page .base-info-content .header-img .header {
  width: 0.56rem;
  height: 0.56rem;
  border-radius: 4px;
}
.patientInfo-page .base-info-content .header-img .sex {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.14rem;
  height: 0.14rem;
}
.patientInfo-page .base-info-content .base-info {
  flex: 1;
  position: relative;
  height: 0.66rem;
}
.patientInfo-page .base-info-content .base-info .name-content {
  height: 0.22rem;
  line-height: 0.22rem;
  display: flex;
  align-items: center;
}
.patientInfo-page .base-info-content .base-info .name-content .name {
  font-size: 0.16rem;
  font-weight: 500;
  color: #222;
}
.patientInfo-page .base-info-content .base-info .name-content img {
  width: 0.16rem;
  height: 0.16rem;
  margin-left: 0.04rem;
}
.patientInfo-page .base-info-content .base-info .phone-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.02rem;
  min-height: 0.2rem;
}
.patientInfo-page .base-info-content .base-info .phone-content .phone {
  font-size: 0.14rem;
  color: #999;
}
.patientInfo-page .base-info-content .base-info .phone-content img {
  width: 0.06rem;
  height: 0.1rem;
}
.patientInfo-page .base-info-content .base-info .tags-content {
  position: relative;
  width: calc(100% - 0.45rem);
  height: 0.2rem;
}
.patientInfo-page .base-info-content .base-info .tags-content .tags {
  display: inline-block;
  height: 0.2rem;
  overflow: hidden;
}
.patientInfo-page .base-info-content .base-info .tags-content .show-more-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.4rem;
  padding: 0.01rem 0.08rem;
  font-size: 0.12rem;
  color: #666;
  background: #F2F3F5;
  border-radius: 2px;
  text-align: center;
}
.patientInfo-page .base-info-content .base-info .tags-content .add-tag-btn {
  width: 0.4rem;
  height: 0.2rem;
  border: 1px solid #F0F0F1;
  border-radius: 2px;
  position: absolute;
  left: calc(100% + 0.06rem);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.patientInfo-page .page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.patientInfo-page .page-content .nav-content {
  display: flex;
  padding: 0.16rem;
  border-top: 1px solid #F0F0F1;
  margin-bottom: 0.08rem;
  background: #fff;
}
.patientInfo-page .page-content .nav-content .item-nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.patientInfo-page .page-content .nav-content .item-nav img {
  width: 0.24rem;
  height: 0.24rem;
  margin-bottom: 0.08rem;
}
.patientInfo-page .page-content .nav-content .item-nav .nav-text {
  font-size: 0.12rem;
  color: #444444;
}
.patientInfo-page .page-content .content {
  flex: 1;
  overflow: hidden;
}
.patientInfo-page .edit-label-drawer-content {
  height: 4.6rem;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.patientInfo-page .edit-label-drawer-content .close-label-modal-btn {
  position: absolute;
  right: 0.15rem;
  top: 0.28rem;
  width: 0.12rem;
}
.patientInfo-page .edit-label-drawer-content .label-box {
  width: 100%;
  height: 3.5rem;
  overflow: auto;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion {
  border: none !important;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion::before {
  display: none!important;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item {
  margin-bottom: 0.24rem;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-header {
  height: 0.28rem;
  line-height: 0.28rem;
  font-size: 0.14rem;
  border: none !important;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-header::after {
  display: none!important;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-header i {
  top: 9px;
  width: 11px;
  height: 11px;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-header .tag-type-title {
  display: inline-block;
  padding-left: 0.03rem;
  line-height: 1;
  border-left: 0.02rem solid #397CFF;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-content {
  padding: 0 0.15rem;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box {
  padding-top: 0.12rem;
  border: none !important;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box::after {
  display: none!important;
}
.patientInfo-page .edit-label-drawer-content div.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .ant-empty-description {
  font-size: 0.13rem;
}
.patientInfo-page .edit-label-drawer-content .save-btn {
  width: 90vw;
  height: 0.4rem;
  background: #397CFF;
  color: #fff;
  font-size: 0.15rem;
  font-weight: 500;
  text-align: center;
  line-height: 0.4rem;
  border-radius: 20px;
  margin-top: 0.08rem;
}
.patientInfo-page .tags-box {
  height: 100%;
  padding: 0 0.16rem;
}
.patientInfo-page .item-tag {
  vertical-align: top;
  display: inline-block;
  padding: 0.01rem 0.08rem;
  font-size: 0.12rem;
  color: #666;
  background: #F2F3F5;
  border-radius: 2px;
  margin-right: 0.06rem;
}
.patientInfo-page .item-tag-edit {
  vertical-align: top;
  display: inline-block;
  padding: 0.01rem 0.08rem;
  font-size: 0.12rem;
  color: #666;
  background: rgba(57, 124, 255, 0.05);
  border-radius: 2px;
  margin-right: 0.06rem;
  border: 1px solid rgba(57, 124, 255, 0.1);
  margin-bottom: 0.05rem;
  cursor: pointer;
}
.patientInfo-page .item-tag-edit.active {
  vertical-align: top;
  display: inline-block;
  padding: 0.01rem 0.08rem;
  font-size: 0.12rem;
  color: #397CFF;
  background: rgba(57, 124, 255, 0.1);
  border-radius: 2px;
  margin-right: 0.06rem;
  border: 1px solid #397CFF;
  margin-bottom: 0.05rem;
  cursor: pointer;
}
.patientInfo-page .reservation_status_UNCONFIRMED {
  color: #ef476f;
}
.patientInfo-page .reservation_status_CONFIRMED {
  color: #00394c;
}
.patientInfo-page .reservation_status_REGISTERED {
  color: #177dbc;
}
.patientInfo-page .reservation_status_ADVISORY {
  color: #177dbc;
}
.patientInfo-page .reservation_status_TREATMENT {
  color: #177dbc;
}
.patientInfo-page .reservation_status_TO_CHARGED {
  color: #06c594;
}
.patientInfo-page .reservation_status_HAS_CHARGED {
  color: #06c594;
}
.patientInfo-page .reservation_status_HAS_OUT {
  color: #aaaaaa;
}
.patientInfo-page .reservation_status_CANCEL,
.patientInfo-page .reservation_status_NO_RESV {
  color: #aaaaaa;
}
.patientInfo-page .reservation_status_COMPLETE {
  color: #06c594;
}
.patientInfo-page .reservation_status_NOTIFY {
  color: #ef476f;
}
